import { unitGateway } from '~/gateways/unitGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { MagicUseCase } from './magicUseCase';
import { GetRentalApplicationUseCase } from './rental-applications/getRentalApplicationUseCase';

export class GetUnitsListingUseCase extends MagicUseCase {
  protected async runLogic(credentials?: RentalApplicationCredentials) {
    const application = this.getState().user.rentalApplication.application;
    if (!application && credentials) {
      await new GetRentalApplicationUseCase().execute(credentials);
    }
    const response = await unitGateway.getUnitsListing();
    this.getState().user.unitsListing = response;
  }
}
