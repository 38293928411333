import { Component, For } from 'solid-js';
import IconTempProperty from '~/assets/tempProperty.png';
import { PresentableUnit } from '~/types/PresentableUnit';

interface ImageGridProps {
  units: PresentableUnit[];
  onSelectionChange: (selectedIds: string[]) => void;
}

export const ImageGrid: Component<ImageGridProps> = (props) => {
  const toggleSelection = (id: string, event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedIds: string[] = props.units
      .map((unit) => {
        if (unit.id === id) {
          return unit.isSelected ? undefined : unit.id;
        } else {
          return unit.isSelected ? unit.id : undefined;
        }
      })
      .filter((unitId): unitId is string => Boolean(unitId));
    props.onSelectionChange(selectedIds);
  };

  return (
    <div class="flex flex-wrap gap-4">
      <For each={props.units}>
        {(unit) => (
          <div
            class="relative flex h-24 min-w-[350px] shrink-0 grow-0 basis-72 items-center rounded-lg border bg-white p-1 shadow-md"
            onClick={(event) => toggleSelection(unit.id, event)}>
            <img
              src={unit.images[0] || IconTempProperty}
              alt={`Unit ${unit.name}`}
              class="h-full w-1/3 object-cover p-1"
              onError={(e) => (e.currentTarget.src = IconTempProperty)}
            />
            <div class="size-full grow p-4">
              <div class="font-semi-bold mr-5 text-base">{unit.name}</div>
              <div class="mr-5 text-xs text-gray-500">{unit.address}</div>
            </div>
            <div
              class={`absolute bottom-0 right-0 mb-2 mr-2 flex size-6 items-center justify-center rounded-full ${
                unit.isSelected ? 'bg-primary-color' : 'bg-gray-100'
              }`}>
              {unit.isSelected && (
                <svg class="size-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
            </div>
          </div>
        )}
      </For>
    </div>
  );
};
